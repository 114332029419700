<template>
  <div>
    <button type="button" class="menu__button menu__button--js">
      <span class="menu__trigger"></span>
    </button>

    <nav class="section menu">
      <div class="container">
        <ul v-if="role != 'prefeitura'" class="menu__list">
          <li class="menu__item" v-for="item in menu" :key="item.to">
            <router-link :to="item.to" class="menu__anchor" :title="item.name">
              <img
                class="menu__icon"
                :src="`/svg/${item.icon}.svg`"
                alt="Extratos"
              />
              <span class="menu__info">{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
        <ul v-if="role == 'prefeitura'" class="menu__list">
          <li class="menu__item" v-for="item in menu2" :key="item.to">
            <router-link :to="item.to" class="menu__anchor" :title="item.name">
              <img
                class="menu__icon"
                :src="`/svg/${item.icon}.svg`"
                alt="Extratos"
              />
              <span class="menu__info">{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'menu-layout',
  data: () => ({
    role: localStorage.getItem('role'),
    menu: [
      {
        name: 'Início',
        to: '/',
        icon: 'icon-extracts'
      },
      {
        name: 'Materiais',
        to: '/materiais',
        icon: 'icon-request-collection'
      },
      {
        name: 'Coletores',
        to: '/usuarios',
        icon: 'icon-collected-materials'
      },
      {
        name: 'Relátórios',
        to: '/reports',
        icon: 'icon-wardrobe'
      },
      {
        name: 'Minhas compras',
        to: '/extratos',
        icon: 'icon-scheduled-collections'
      }
    ],
    menu2: [
      {
        name: 'Dashboard',
        to: '/',
        icon: 'icon-extracts'
      },
      {
        name: 'Relátórios',
        to: '/reports',
        icon: 'icon-wardrobe'
      }
    ]
  })
}
</script>

<style>
.menu__item__right {
  float: right;
}

.credit {
  color: #16b666;
}
</style>
